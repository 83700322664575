import { useState, useEffect } from 'react';
import { ClipLoader } from 'react-spinners';

import './styles/hompage.css';
import './styles/exchangeForm.css';
import selectStyles from './styles/selectConst';
import { fetchRate } from '../../api/get';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { getCountryLabel, countryFlagDict } from '../fixtures/flags';
import currencyIcons from '../fixtures/currencyIcons';
import { SingleValue } from './common';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { exchangeSubmit, clientSubmit } from '../../api/post';
import Cookies from 'js-cookie';
import AmountInput from './exchange/AmountInput';
import UserInfoInput from './exchange/UserInfoInput';
import BankRequisiteInput from './exchange/BankRequisiteInput';
// import CryptoRequisiteInput from './exchange/CryptoRequisiteInput';
import {
  isCashCurrency,
  isBankRequisite,
  // isCryptoCurrency,
} from '../utils/validator';

const formatOptions = (options, t) =>
  options.map((option) => {
    // Проверяем, это валюта, страна или город
    const isCurrency = currencyIcons[option.name];
    const isCountry = countryFlagDict[option.name];

    const localizedCountryName =
      isCountry && t(`countries.${option.name}`, { defaultValue: option.name });

    if (isCurrency) {
      return {
        value: option.id,
        label: (
          <>
            <img
              src={currencyIcons[option.name]}
              className="currency-svg-icon"
              alt={option.name}
            />
            {option.name.replace('Cash', t('cash'))}
          </>
        ),
      };
    }

    if (isCountry) {
      return {
        value: option.id,
        label: `${countryFlagDict[option.name]} ${localizedCountryName}`,
      };
    }

    return {
      value: option.id,
      label: t(`cryptoCity.${option.name}.baseCity`, { defaultValue: option.name }),
    };
  });

const tetherMapping = {
  USDT: 'Tether TRC20 (USDT)',
  USDTTRC20: 'Tether TRC20 (USDT)',
};

const formatCurrencyFromURL = (urlSegment) => {
  return tetherMapping[urlSegment] || urlSegment;
};

const Exchange = ({
  countries,
  fromCurrencyURL,
  toCurrencyURL,
  cityURL,
  networkLables,
  urlPath,
}) => {
  const { t } = useTranslation();

  const [Country, setCountry] = useState(null);
  const [City, setCity] = useState('');
  const [fromCurrency, setFromCurrency] = useState('');
  const [toCurrency, setToCurrency] = useState('');
  const [rate, setRate] = useState(null);
  const [loadingRate, setLoadingRate] = useState(false);

  const [cities, setCities] = useState([]);
  const [currencies, setCurrencies] = useState([]);

  const [actionType, setActionType] = useState('navigate');

  const onFormSubmit = async (data) => {
    setLoadingRate(true);
    const isCash =
      isCashCurrency(fromCurrency.label) || isCashCurrency(toCurrency.label);

    // Если хотя бы одна из валют наличная и выбран город, добавляем город в URL
    let queryParams = new URLSearchParams({
      cur_from: fromCurrency.code || fromCurrency.label,
      cur_to: toCurrency.code || toCurrency.label,
    });

    if (isCash && City) {
      queryParams.append('city', City.code || City.label);
    }
    const newUrl = `${urlPath}${queryParams.toString()}`;

    if (!isCash) {
      data.city_id = null
    }
    if (actionType === 'navigate') {
      navigate(newUrl);
    } else if (actionType === 'log') {
      data.exchange_rate = rate.rate;
      const agentId = Cookies.get('agentId');
      data.user = agentId;
      // 1. Создание клиента
      const clientResponse = await clientSubmit(data);
      if (clientResponse && clientResponse.data) {
        // 2. Получение ID клиента
        const clientId = clientResponse.data.id;
        // 3. Добавление ID клиента в данные обмена и отправка данных обмена
        data.client = clientId;
        
        data.rates = {
          give: rate.give,
          get: rate.get
        }
        console.log(data)
        const exchangeResponse = await exchangeSubmit(data);

        if (exchangeResponse && exchangeResponse.data) {
          navigate(`/exchange-details/${exchangeResponse.data.id}`);
        } else {
          console.log('Ошибка при создании обмена!');
        }
      } else {
        console.log('Ошибка при создании клиента!');
      }
    }
    setLoadingRate(false);
  };

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const showCityField =
    (fromCurrency && isCashCurrency(fromCurrency.label)) ||
    (toCurrency && isCashCurrency(toCurrency.label));

  useEffect(() => {
    if (countries.length === 0) return;
    let foundCountry = null;

    if (cityURL) {
      foundCountry = countries.find((country) =>
        country.cities.some(
          (city) => city.code === cityURL || city.name === cityURL,
        ),
      );
      const foundCity = foundCountry?.cities.find(
        (city) => city.code === cityURL || city.name === cityURL,
      );
      if (foundCity) {
        setCity({
          label: foundCity.name,
          value: foundCity.id,
          code: foundCity.code,
        });
        setValue('city_id', foundCity.id);
      }
    }

    if (!foundCountry && (fromCurrencyURL || toCurrencyURL)) {
      let potentialCurrency = fromCurrencyURL;
      const countriesWithToCurrency = countries.filter((country) =>
        country.currencies.some(
          (currency) =>
            currency.code === toCurrencyURL || currency.name === toCurrencyURL,
        ),
      );

      if (countriesWithToCurrency.length === 1) {
        potentialCurrency = toCurrencyURL;
      }

      foundCountry = countries.find((country) =>
        country.currencies.some(
          (currency) =>
            currency.code === potentialCurrency ||
            currency.name === potentialCurrency,
        ),
      );
    }

    if (foundCountry) {
      setCountry({
        label: getCountryLabel(foundCountry.name),
        value: foundCountry.id,
      });
      setCities(foundCountry.cities);
      setCurrencies(foundCountry.currencies);

      if (fromCurrencyURL) {
        setActionType('log');
        const formattedFromCurrency = formatCurrencyFromURL(fromCurrencyURL);
        const foundCurrency = foundCountry.currencies.find(
          (currency) =>
            currency.code === formattedFromCurrency ||
            currency.name === formattedFromCurrency,
        );
        if (foundCurrency) {
          setFromCurrency({
            code: foundCurrency.code,
            label: foundCurrency.name,
            value: foundCurrency.id,
            isCrypto: foundCurrency.is_crypto,
          });
          setValue('from_currency_id', foundCurrency.id);
        }
      }

      if (toCurrencyURL) {
        const formattedToCurrency = formatCurrencyFromURL(toCurrencyURL);
        const foundToCurrency = foundCountry.currencies.find(
          (currency) =>
            currency.code === formattedToCurrency ||
            currency.name === formattedToCurrency,
        );
        if (foundToCurrency) {
          setToCurrency({
            code: foundToCurrency.code,
            label: foundToCurrency.name,
            value: foundToCurrency.id,
            isCrypto: foundToCurrency.is_crypto,
          });
          setValue('to_currency_id', foundToCurrency.id);
        }
      }
    }
  }, [countries, cityURL, fromCurrencyURL, toCurrencyURL, setValue]);

  // Обновляем состояние города в зависимости от выбранных валют
  useEffect(() => {
    if (
      isCashCurrency(fromCurrency.label) ||
      isCashCurrency(toCurrency.label)
    ) {
      // Если одна из валют наличные, оставляем город как есть
    } else {
      // Если обе валюты безналичные, устанавливаем город в пустую строку
      setCity('');
    }
  }, [fromCurrency, toCurrency]);

  useEffect(() => {
    const fetchExchangeRate = async () => {
      if (fromCurrency && toCurrency) {
        setLoadingRate(true);
        try {
          const response = await fetchRate(
            fromCurrency.value,
            toCurrency.value,
            City ? City.value : '', // Если город пустой, передаем пустую строку
          );

          if (response.data.result) {
            setRate(response.data.result.rate);
          }
        } catch (error) {
          console.error('Error fetching rate:', error);
          setRate(null);
        } finally {
          setLoadingRate(false);
        }
      }
    };

    fetchExchangeRate();
  }, [fromCurrency, toCurrency, City]);

  const handleCountryChange = async (selectedCountry) => {
    if (!selectedCountry) return;

    setCities(selectedCountry.cities);
    setCurrencies(selectedCountry.currencies);
    setRate(null);
  };

  const handleRateChange = async (fromCurrencyId, toCurrencyId, cityId) => {
    setLoadingRate(true);
    try {
      const response = await fetchRate(fromCurrencyId, toCurrencyId, cityId);
      setRate(response.data.result.rate);
    } catch (error) {
      setRate(null);
    } finally {
      setLoadingRate(false);
    }
  };

  const handleSelectChange = async (selectedItem, setType, optionsArray) => {
    const selectedOption = optionsArray.find(
      (option) => option.id === parseInt(selectedItem.value),
    );
    setType({
      code: selectedOption.code,
      value: selectedOption.id,
      label: selectedOption.name,
      isCrypto: selectedOption.is_crypto,
    });

    if (setType === setCountry) {
      setType({
        value: selectedOption.id,
        label: getCountryLabel(selectedOption.name),
      });
      setCity('');
      setFromCurrency('');
      setToCurrency('');

      await handleCountryChange(selectedOption);
    }
    if (setType === setCity && selectedOption && fromCurrency && toCurrency) {
      await handleRateChange(
        fromCurrency.value,
        toCurrency.value,
        selectedOption.id,
      );
    }
    if (setType === setFromCurrency && selectedOption && City && toCurrency) {
      setToCurrency('');
      setRate(null);
    }
    if (setType === setToCurrency && selectedOption && City && fromCurrency) {
      await handleRateChange(fromCurrency.value, selectedOption.id, City.value);
    }
  };
  const navigate = useNavigate();

  const shouldShowAmountFields = () => {
    // Если обе валюты безналичные
    if (
      fromCurrency &&
      !isCashCurrency(fromCurrency.label) &&
      toCurrency &&
      !isCashCurrency(toCurrency.label)
    ) {
      return true;
    }

    // Если одна из валют наличные и город выбран
    if (
      City &&
      ((fromCurrency && isCashCurrency(fromCurrency.label)) ||
        (toCurrency && isCashCurrency(toCurrency.label)))
    ) {
      return true;
    }

    return false;
  };

  return (
    <>
      <div
        className={`flex-container ${
          !(rate && rate.give && rate.get) ? 'center-form-only' : ''
        }`}
      >
        <div className='exchange-form'>
          <div className='exchange-input-section'>
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <div className='input-container'>
                <Select
                  styles={selectStyles}
                  value={Country}
                  options={formatOptions(countries, t)}
                  onChange={(option) =>
                    handleSelectChange(option, setCountry, countries)
                  }
                  placeholder={t('main.chooseCountry')}
                />
                <label>{t('main.chooseCountry')}</label>
              </div>
              <Controller
                name='from_currency_id'
                control={control}
                render={({ field: { onChange, value, ...field } }) => (
                  <div className='input-container'>
                    <Select
                      {...field}
                      value={fromCurrency}
                      components={{ SingleValue }}
                      styles={selectStyles}
                      options={formatOptions(currencies, t)}
                      placeholder={t('main.chooseFromCur')}
                      onChange={(option) => {
                        onChange(option.value);
                        handleSelectChange(option, setFromCurrency, currencies);
                      }}
                    />
                    <label>{t('main.chooseFromCur')}</label>
                  </div>
                )}
              />
              {isBankRequisite(fromCurrency || null) && (
                <>
                  <BankRequisiteInput control={control} errors={errors} />
                  <span className='info-msg'>
                    {t('main.fromCurrencyInfoMsg')}
                  </span>
                </>
              )}

              <Controller
                name='to_currency_id'
                control={control}
                render={({ field: { onChange, value, ...field } }) => (
                  <div className='input-container'>
                    <Select
                      {...field}
                      components={{ SingleValue }}
                      styles={selectStyles}
                      value={toCurrency}
                      placeholder={t('main.chooseToCur')}
                      options={formatOptions(
                        currencies.filter((option) => {
                          if (fromCurrency && fromCurrency.isCrypto) {
                            return !option.is_crypto;
                          } else {
                            return option.is_crypto;
                          }
                        }),
                      t)}
                      onChange={(option) => {
                        onChange(option.value);
                        handleSelectChange(option, setToCurrency, currencies);
                      }}
                    />
                    <label>{t('main.chooseToCur')}</label>
                  </div>
                )}
              />
              {isBankRequisite(toCurrency) && (
                <BankRequisiteInput control={control} errors={errors} />
              )}
              {/* {isCryptoCurrency(toCurrency) &&
                isBankRequisite(fromCurrency) && (
                  <CryptoRequisiteInput
                    control={control}
                    errors={errors}
                    networkLables={networkLables}
                    currencyId={toCurrency.value}
                  />
                )} */}
              {showCityField && (
                <Controller
                  name='city_id'
                  control={control}
                  render={({ field: { onChange, value, ...field } }) => (
                    <div className='input-container'>
                      <Select
                        {...field}
                        value={City}
                        styles={selectStyles}
                        options={formatOptions(cities, t)}
                        placeholder={t('main.chooseCity')}
                        onChange={(option) => {
                          onChange(option.value);
                          handleSelectChange(option, setCity, cities);
                        }}
                      />
                      <label>{t('main.chooseCity')}</label>
                    </div>
                  )}
                />
              )}
              {shouldShowAmountFields() && rate && rate.give && rate.get && (
                <AmountInput
                  control={control}
                  rate={rate}
                  fromCurrency={fromCurrency}
                  toCurrency={toCurrency}
                  register={register}
                  setValue={setValue}
                  errors={errors}
                />
              )}
              {fromCurrencyURL && toCurrencyURL && (
                <UserInfoInput control={control} errors={errors} rate={rate}/>
              )}
              <button
                type='submit'
                className='contact-button'
                disabled={
                  loadingRate ||
                  !fromCurrency ||
                  !toCurrency ||
                  !rate?.give ||
                  !Country ||
                  (showCityField && !City) ||
                  loadingRate
                }
              >
                {loadingRate ? (
                  <ClipLoader size={20} color={'#9DFBFE'} />
                ) : (
                  t('main.submitLabel')
                )}
              </button>
            </form>
          </div>
        </div>

        {!loadingRate &&
          fromCurrency &&
          toCurrency &&
          City &&
          Country &&
          !rate && (
            <div className='exchange-info'>
              <div className='exchange-row'>
                <div className='error-message'>
                  <p>
                    {t('main.notChange')}
                    {fromCurrency.label}
                    {t('main.to')}
                    {toCurrency.label}
                    {t('main.in')}
                    {City.label}
                  </p>
                </div>
              </div>
            </div>
          )}
      </div>
    </>
  );
};

export default Exchange;
