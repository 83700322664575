import React, { useState, useEffect, useRef, useContext } from 'react';
import './styles/hompage.css';
import Exchange from './Exchange';
import Advantages from './Advantages';
import WelcomeSection from './WelcomeComponent';
import { useTranslation } from 'react-i18next';
import { fetchLastsExchanges } from '../../api/get';
import LastExchanges from './LastExchanges';
import { useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Cities from './Cities';
import Title from './Title';
import { fetchCountries, fetchNetworkLables } from '../../api/get';
import Cookies from 'js-cookie';
import Partners from './Partners';
import { incrementReferralLinkClick } from '../../api/post';
import CryptoExchangeProcess from './CryptoExchangeProcess';
import CompanyInfo from './companyInfo';
import { Context } from './../../index';
import { observer } from 'mobx-react-lite';

const Homepage = () => {
  const { t } = useTranslation();
  const { store } = useContext(Context);

  const [exchanges, setExchanges] = useState([]);
  const [countries, setCountries] = useState([]);
  const [networkLables, setnetworkLables] = useState({});

  const exchangeRef = useRef(null);
  const [searchParams] = useSearchParams();
  const fromCurrency = searchParams.get('cur_from');
  const toCurrency = searchParams.get('cur_to');
  const city = searchParams.get('city');
  const agentId = searchParams.get('agent_id');

  const handleScrollToExchange = (e) => {
    e.preventDefault();
    const elementTop = exchangeRef.current.getBoundingClientRect().top;
    const halfScreenHeight = window.innerHeight / 2;
    window.scroll({
      top: elementTop + window.pageYOffset - halfScreenHeight,
      behavior: 'smooth',
    });
  };
  useEffect(() => {
    const getCountries = async () => {
      const response = await fetchCountries();
      if (response.success) {
        setCountries(response.data);
      }
      const networkLablesResponese = await fetchNetworkLables();
      if (networkLablesResponese.success) {
        setnetworkLables(networkLablesResponese.data);
      }
    };
    getCountries();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (agentId) {
      const cleanAgentId = agentId.split('?')[0];
      Cookies.set('agentId', cleanAgentId, { expires: 365 });

      if (!window.hasIncremented) {
        window.hasIncremented = true;
        incrementReferralLinkClick({ agent_id: cleanAgentId });
      }
    }
  }, [agentId]);

  useEffect(() => {
    const agentId = Cookies.get('agentId');
    if (agentId) {
      store.setViewTgBot(agentId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchLastsExchanges();
        setExchanges(response.data.results);
      } catch (error) {
        setExchanges(null);
      }
    };
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className='hompage-wrapper'>
      <Helmet>
        <title>{t('SEO.homePageTitle')}</title>
        <link rel='canonical' href='https://dimmarex.com/' />
        <meta property='og:title' content={t('SEO.homePageTitle')} />
        <meta name='description' content={t('SEO.homePageDescription')} />
        <meta
          property='og:description'
          content={t('SEO.homePageDescription')}
        />
      </Helmet>
      <div className='exchange-wrapper'>
        <div className='content-with-left-sidebar'> </div>
        <div className='exchange-section  form-section'>
          <div className='exchange-title'>
            <h1
              dangerouslySetInnerHTML={{ __html: t('main.exchangeTitleH1') }}
            ></h1>
          </div>
          <div className='exchange-container'>
            <div className='exchange-container-name'>{t('main.exchange')}</div>

            <div className='neutral-div' ref={exchangeRef}>
              <Exchange
                countries={countries}
                fromCurrencyURL={fromCurrency}
                toCurrencyURL={toCurrency}
                cityURL={city}
                networkLables={networkLables}
                urlPath='/exchange?'
              />
            </div>
          </div>
          <Title></Title>
        </div>
        {exchanges && exchanges.length > 0 && (
          <LastExchanges exchanges={exchanges} />
        )}

        <div className='content-with-right-sidebar'> </div>
      </div>
      <WelcomeSection />
      <Advantages handleScrollToExchange={handleScrollToExchange} />
      <CryptoExchangeProcess />
      <CompanyInfo />
      <Cities countries={countries} />
      <Partners />
    </div>
  );
};

export default observer(Homepage);
