import { useTranslation } from 'react-i18next';
import defaultBlockImg from '../img/advantageIcons/defaultBlock.svg';
import { Helmet } from 'react-helmet';

const ImporterBlocks = ({ blocks }) => {
  return (
    <div className='advantages-container b-white p-0'>
      <div className='advantages-container__row'>
        {blocks.map((block, index) => (
          <div key={index} className='adv-content-block b-grey p-16-16'>
            <img
              src={defaultBlockImg}
              alt='defaultBlockImg'
              width={32}
              height={32}
            />
            <p
              className='font-regular-20 font-gr'
              dangerouslySetInnerHTML={{ __html: block.desc }}
            ></p>
          </div>
        ))}
      </div>
    </div>
  );
};

const Invoice = () => {
  const { t } = useTranslation();
  const importerBlocksData = [
    { desc: t('invoice.payInvoice.item1') },
    { desc: t('invoice.payInvoice.item2') },
    { desc: t('invoice.payInvoice.item3') },
  ];
  return (
    <div className='hompage-wrapper'>
      <Helmet>
      <title>{t('SEO.invoicePageTitle')}</title>
      <meta name='description' content={t('SEO.invoicePageDescription')} />
      <link rel='canonical' href={`${t('SEO.url')}invoice`} />
      <meta property='og:title' content={t('SEO.invoicePageTitle')} />
        <meta
          property='og:description'
          content={t('SEO.invoicePageDescription')}
        />
      </Helmet>
      <div className='advantage-wrapper'>
        <div className='left-sidebar-background-img'></div>
        <div className='advantage-content'>
          <h1
            className='block-title-h2'
            dangerouslySetInnerHTML={{ __html: t('invoice.title') }}
          ></h1>
          <div className='advantages-container b-white pre-wrap-text'>
            <p
              className='pink-b'
              dangerouslySetInnerHTML={{ __html: t('invoice.desc') }}
            ></p>
            <h2
              className='block-title-h2 top-50 bot-30'
              dangerouslySetInnerHTML={{
                __html: t('invoice.payInvoice.title'),
              }}
            ></h2>
            <div className='content-block'>
              <p
                dangerouslySetInnerHTML={{
                  __html: t('invoice.payInvoice.desc'),
                }}
              ></p>
            </div>
            <ImporterBlocks blocks={importerBlocksData} />

            {/* Для импортеров */}
            <h2
              className='block-title-h2'
              dangerouslySetInnerHTML={{
                __html: t('invoice.importers.title'),
              }}
            ></h2>
            <div className='adv-content-block'>
              <h3 className='font-regular-20'>
                {t('invoice.importers.option1.title')}
              </h3>
              <div className='advantages-container__row'>
                <div className='adv-column'>
                  <ol className='adv-content'>
                    <li>{t('invoice.importers.option1.step1')}</li>
                    <li>{t('invoice.importers.option1.step2')}</li>
                    <li>{t('invoice.importers.option1.step3')}</li>
                  </ol>
                  <h4 className='font-regular-20 font-gr'>
                    {t('invoice.importers.documentsTitle')}
                  </h4>
                  <ol className='adv-content'>
                    <li>{t('invoice.importers.documents.item1')}</li>
                    <li>{t('invoice.importers.documents.item2')}</li>
                    <li>{t('invoice.importers.documents.item3')}</li>
                    <li>{t('invoice.importers.documents.item4')}</li>
                    <li>{t('invoice.importers.documents.item5')}</li>
                  </ol>
                </div>

                <div className='adv-column'>
                  <h4 className='font-regular-20 font-gr'>
                    {t('invoice.importers.conditionsTitle')}
                  </h4>
                  <ul className='adv-content'>
                    <li>{t('invoice.importers.conditions.item1')}</li>
                    <li>{t('invoice.importers.conditions.item2')}</li>
                  </ul>
                  <div className='adv-content-block b-grey'>
                    <h4 className='font-regular-20'>
                      {t('invoice.importers.bankInfoTitle')}
                    </h4>
                    <p
                      className='adv-content'
                      dangerouslySetInnerHTML={{
                        __html: t('invoice.importers.bankInfoP'),
                      }}
                    ></p>
                  </div>
                  <div className='social-items-container'>
                    <div className='button-container'>
                      <a
                        href='tg://resolve?domain=DIMMAR_EX'
                        target='_blank'
                        rel='noopener noreferrer'
                        className='subscribe-button'
                      >
                        {t('invoice.telegram')}
                      </a>
                    </div>
                    <div
                      className='font-regular-20'
                      dangerouslySetInnerHTML={{
                        __html: t('invoice.importers.getTelegram'),
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className='adv-content-block'>
              <h3 className='font-regular-20'>
                {t('invoice.importers.option2.title')}
              </h3>
              <div className='advantages-container__row'>
                <div className='adv-column'>
                  <ol className='adv-content'>
                    <li>{t('invoice.importers.option2.step1')}</li>
                    <li>{t('invoice.importers.option2.step2')}</li>
                    <li>{t('invoice.importers.option2.step3')}</li>
                  </ol>
                  <h4 className='font-regular-20 font-gr'>
                    {t('invoice.importers.documentsTitle')}
                  </h4>
                  <ol className='adv-content'>
                    <li>{t('invoice.importers.documents.item1')}</li>
                    <li>{t('invoice.importers.documents.item2')}</li>
                    <li>{t('invoice.importers.documents.item3')}</li>
                  </ol>
                </div>
                <div className='adv-column'>
                  <h4 className='font-regular-20 font-gr'>
                    {t('invoice.importers.conditionsTitle')}
                  </h4>
                  <ul className='adv-content'>
                    <li>{t('invoice.importers.conditions.item3')}</li>
                    <li>{t('invoice.importers.conditions.item4')}</li>
                  </ul>
                  <div className='adv-content-block b-grey'>
                    <h4 className='font-regular-20'>
                      {t('invoice.importers.benefitsTitle')}
                    </h4>
                    <p className='adv-content'>
                      {t('invoice.importers.benefits')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* Для экспортеров */}
            <h2
              className='block-title-h2'
              dangerouslySetInnerHTML={{
                __html: t('invoice.exporters.title'),
              }}
            ></h2>

            <div className='adv-content-block'>
              <div className='advantages-container__row'>
                <div className='adv-column'>
                  <ol className='adv-content'>
                    <li>{t('invoice.exporters.item1')}</li>
                    <li>{t('invoice.exporters.item2')}</li>
                  </ol>
                  <p
                    className='font-regular-20 font-gr'
                    dangerouslySetInnerHTML={{
                      __html: t('invoice.exporters.getTelegram'),
                    }}
                  ></p>
                </div>
                <div className='adv-column'>
                  <h4 className='font-regular-20'>
                    {t('invoice.exporters.benefits')}
                  </h4>
                  <div className='button-container'>
                    <a
                      href='tg://resolve?domain=DIMMAR_EX'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='subscribe-button'
                    >
                      {t('invoice.telegram')}
                    </a>
                  </div>
                </div>
              </div>
            </div>

            {/* Партнерская программа */}
          </div>
        </div>
        <div className='right-sidebar-background-img'></div>
      </div>
      <div className='welcome-container'>
        <div className='content-block-container'>
          <h2
            className='block-title-h2'
            dangerouslySetInnerHTML={{
              __html: t('invoice.partners.title'),
            }}
          ></h2>

          <div className='invoice-block'>
            <p
              dangerouslySetInnerHTML={{
                __html: t('invoice.partners.desc'),
              }}
            ></p>
          </div>
          <a
            href='tg://resolve?domain=DIMMAR_EX'
            target='_blank'
            rel='noopener noreferrer'
            class='telegram-button m-w-40'
          >
            {t('invoice.partners.telegram')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
