import React, { useContext, useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { FaGlobe } from 'react-icons/fa';
import Cookies from 'js-cookie';

import './style.css';
import logoImage from './../../img/logo.svg';
import { NavLink, useMatch, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Context } from '../../index';
import { DASHBOARD } from '../../routes/paths';
import useCurrentLanguage from '../hooks/useCurrentLanguage,';
import {
  AVAILABLE_LANGUAGES,
  DEFAULT_LANGUAGE,
} from '../../feature/LanguageWrapper';
// import useWindowSize from '../hooks/windowSize';
export const NavItem = ({ to, children, onClick }) => {
  const lng = useCurrentLanguage();
  const defaultLanguage = 'ru';

  const languagePrefix = lng !== defaultLanguage ? `/${lng}` : '';
  const fullPath = `${languagePrefix}${to.startsWith('/') ? '' : '/'}${to}`;

  let match = useMatch(fullPath);

  return (
    <li className={match ? 'active-link' : ''}>
      <NavLink to={fullPath} onClick={onClick}>
        {children}
      </NavLink>
    </li>
  );
};

export const CustomNavLink = ({ to, children }) => {
  const lng = useCurrentLanguage();
  const defaultLanguage = 'ru';

  const languagePrefix = lng !== defaultLanguage ? `/${lng}` : '';
  const fullPath = `${languagePrefix}${to.startsWith('/') ? '' : '/'}${to}`;
  return <NavLink to={fullPath}>{children}</NavLink>;
};

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const location = useLocation();
  const navigate = useNavigate();

  const changeLanguage = (newLng) => {
    i18n.changeLanguage(newLng);
    setLanguage(newLng);
    Cookies.set('i18next', newLng, { expires: 365 });
    let pathname = location.pathname;
    const pathParts = pathname.split('/').filter(Boolean);

    // Убираем текущий языковой префикс, если он есть
    if (AVAILABLE_LANGUAGES.includes(pathParts[0])) {
      pathParts.shift();
    }

    if (newLng !== DEFAULT_LANGUAGE) {
      pathname = `/${newLng}/${pathParts.join('/')}`;
    } else {
      pathname = `/${pathParts.join('/')}`;
    }
    pathname = pathname.replace(/\/+/g, '/');

    if (pathname === '') {
      pathname = '/';
    }

    navigate({ pathname, search: location.search }, { replace: true });
    setIsDropdownOpen(false);
    window.location.reload();
  };

  useEffect(() => {
    const savedLanguage = Cookies.get('i18next') || DEFAULT_LANGUAGE;
    if (savedLanguage !== i18n.language) {
      i18n.changeLanguage(savedLanguage);
      changeLanguage(savedLanguage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className='language-switcher' ref={dropdownRef}>
      <div className='dropdown'>
        <button className='dropdown-btn' onClick={toggleDropdown}>
          <FaGlobe /> {language.toUpperCase()} <span className='arrow'>▼</span>
        </button>
        {isDropdownOpen && (
          <ul className='dropdown-menu'>
            <li onClick={() => changeLanguage('en')}>🏴󠁧󠁢󠁥󠁮󠁧󠁿 English</li>
            <li onClick={() => changeLanguage('ru')}>🇷🇺 Русский</li>
            <li onClick={() => changeLanguage('tr')}>🇹🇷 Türkçe</li>
          </ul>
        )}
      </div>
    </div>
  );
};

const Navbar = () => {
  const { store } = useContext(Context);

  const { t, i18n } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <header>
      <div className='navbar'>
        <NavLink to='/' onClick={closeMenu}>
          <div className='navbar-logo'>
            <img
              src={logoImage}
              alt='Logo'
              className='logo'
              width='100'
              height='100'
            />
          </div>
        </NavLink>

        <div className='menu-toggle' onClick={toggleMenu}>
          <span className='bar'></span>
          <span className='bar'></span>
          <span className='bar'></span>
        </div>

        <nav className={`navbar-items ${menuOpen ? 'active' : ''}`}>
          <div className='close-menu' onClick={toggleMenu}>
            ✕
          </div>
          <ul>
            <NavItem to='/' onClick={closeMenu}>
              {t('navbar.home')}
            </NavItem>
            <NavItem to='/kyc' onClick={closeMenu}>
              {t('navbar.kyc')}
            </NavItem>
            <NavItem to='/reviews' onClick={closeMenu}>
              {t('navbar.reviews')}
            </NavItem>
            <NavItem to='/contacts' onClick={closeMenu}>
              {t('navbar.contacts')}
            </NavItem>
            <NavItem to='/rules' onClick={closeMenu}>
              {t('navbar.rules')}
            </NavItem>
            <NavItem to='/partnership' onClick={closeMenu}>
              {t('navbar.partnership')}
            </NavItem>
            {store.viewTgBot && (
              <NavItem to='/invoice' onClick={closeMenu}>
                {t('navbar.invoice')}
              </NavItem>
            )}
            {i18n.language === 'ru' && (
              <NavItem to='/articles' onClick={closeMenu}>
                {t('navbar.articles')}
              </NavItem>
            )}
            <LanguageSwitcher />

            {store.isAuth && (
              <div className='auth-container'>
                <NavItem to={DASHBOARD} onClick={closeMenu}>
                  {t('navbar.dashboard')}
                </NavItem>
              </div>
            )}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default observer(Navbar);
